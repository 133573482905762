import { useEffect, useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import {
  listSupportStatements,
  getUserNewMessage,
  getHelpCenterFaqCache
} from 'src/graphql/queries'
import VoucherIcon from 'src/components/Icon/icons/voucher-icon.svg'
import IssueIcon from 'src/components/Icon/icons/issue-icon.svg'
import useNavigationLogic from '../../../hooks/useNavigationLogic'
import { useHistory } from 'react-router'

const useLandingPageLogic = (
  route,
  props,
  isPageChecked,
  setIsPageChecked,
  setIsEcApp
) => {
  const [menu, setMenu] = useState([])
  const [faq, setFaq] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [hasNewMessage, setHasNewMessage] = useState(0)
  const [isTngMp, setIsTngMp] = useState(true)
  const [userPlatform, setUserPlatform] = useState()
  const [notify, setNotify] = useState({
    show: false,
    severity: '',
    message: ''
  })
  const { checkUserPlatform, getJwtToken } = useNavigationLogic(
    route,
    setUserPlatform,
    setIsTngMp,
    setNotify,
    'landing',
    null,
    props.location
  )
  const history = useHistory()

  const checkPrevPath = () => {
    if (history.location.state && history.location.state.prevPath) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    let platform = checkUserPlatform()
    if (
      route === 'mobile' &&
      (platform === 'ecommerce' || platform === null) &&
      sessionStorage.getItem('isEcApp') === null
    ) {
      setIsEcApp(true)
      sessionStorage.setItem('isEcApp', true)
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    setIsLoading(true)
    let platform = checkUserPlatform()
    getJwtToken(platform)
    if (
      route === 'mobile' &&
      !isPageChecked &&
      sessionStorage.getItem('isEcApp') === 'true' &&
      checkPrevPath()
    ) {
      // setIsSecondPage(true)
      setIsEcApp(true)
      setIsPageChecked(true)
    }
    if (global.jwtToken != null) {
      getUserMessage()
    }
    if (route === 'mobile') {
      getFaqData(platform)
    }
  }, [])

  async function getFaqData(platform) {
    const menuList = [
      {
        icon: IssueIcon,
        topic: 'Report Issue',
        path: '/order',
        sequence: -2,
        platform: platform
      },
      {
        icon: 'message',
        topic: 'Chat History',
        path: '/chat',
        sequence: 0,
        platform: platform
      }
    ]

    if (platform === 'ecommerce') {
      menuList.push({
        icon: VoucherIcon,
        topic: 'I have issue with my voucher order',
        path: '/voucher-order',
        sequence: -1,
        platform: platform
      })
    }

    if (platform !== 'pudoparcel') {
      menuList[0] = {
        icon: 'food',
        topic: 'I have issue with my order',
        path: '/order',
        sequence: -2,
        platform: platform
      }
    }

    const faqList = []
    const params = {
      filter: null,
      limit: 100,
      nextToken: null,
      platform: platform
    }
    const inactiveparams = {
      filter: { statementType: { eq: 'InactiveOrder' } }
      // filter: {
      //   and: [{ statementType: { eq: 'Parcel-Completed' } },  { platform: { eq: 'pudoparcel' } }],
      // },
    }
    try {
      const response = await API.graphql(
        graphqlOperation(getHelpCenterFaqCache, params)
      )
      const inactive = await API.graphql(
        graphqlOperation(listSupportStatements, inactiveparams)
      )
      if (response.data.getHelpCenterFaqCache) {
        response.data.getHelpCenterFaqCache.items.map((item) => {
          var details = {
            icon: 'info',
            faqId: item.faqId,
            parentId: item.parentId,
            topic: item.question,
            sequence: item.sequence,
            path: '/faq',
            answer: item.answer,
            category: item.faqCategory,
            platform: item.platform
          }
          faqList.push(details)
        })
      }
      function compare_item(a, b) {
        if (a.sequence < b.sequence) {
          return -1
        } else if (a.sequence > b.sequence) {
          return 1
        } else {
          return 0
        }
      }
      function sortList(currentFaqList) {
        const sortSequence = [
          'MYACCOUNT',
          'BENEFITS & PRIVILEGE',
          'PAYMENT',
          'E-COMMERCE ORDERING',
          'VOUCHER',
          'TECHNICAL ISSUE'
        ]

        currentFaqList.sort((a, b) => {
          return (
            sortSequence.indexOf(a.category) - sortSequence.indexOf(b.category)
          )
        })
        return currentFaqList
      }

      let uniqueFaqListByCat = []
      for (let i = 0; i < faqList.length; i++) {
        const element = faqList[i]

        let duplicate = false
        uniqueFaqListByCat.forEach((e) => {
          if (e.category == element.category) {
            duplicate = true
          }
        })

        if (!duplicate) {
          uniqueFaqListByCat.push(element)
        }
      }

      menuList.sort(compare_item)
      uniqueFaqListByCat = sortList(uniqueFaqListByCat)
      setMenu(menuList)
      setFaq(uniqueFaqListByCat)

      setTimeout(() => {
        setIsLoading(false)
      }, 500)
    } catch (e) {
      console.log('err', e)
    }
  }

  async function getUserMessage() {
    try {
      const params = {
        token: global.jwtToken
      }

      const response = await API.graphql(
        graphqlOperation(getUserNewMessage, params)
      )
      let total = 0
      response.data.getUserNewMessage.forEach((issue) => {
        total += issue.hasNewMessage
      })
      if (total > 0) {
        setHasNewMessage({ message: 'New Message' })
      }
    } catch (error) {
      console.log(error)
    }
  }

  return {
    menu,
    faq,
    isLoading,
    hasNewMessage,
    isTngMp,
    userPlatform,
    setUserPlatform,
    notify,
    setNotify
  }
}

export default useLandingPageLogic
