import React, { useState, useEffect } from 'react'
import {
  Box,
  Typography,
  makeStyles,
  Link,
  withStyles
} from '@material-ui/core'
import { Paper, BackButton, Button } from 'src/components'
import { getToken } from 'src/utils/helper'
import _ from 'lodash'
import purify from 'dompurify'
import './FAQPageDetail.scss'

const useStyles = makeStyles(({ palette, spacing }) => ({
  center: {
    display: 'flex',
    justifyContent: 'center'
  },
  topic: {
    fontSize: '0.9rem',
    color: palette.primary.main
    // textAlign:'justify',
    // textJustify:'inter-word'
  },
  faqMsg: {
    fontSize: '0.9rem',
    margin: spacing(2.5, 0),
    textAlign: 'justify',
    textJustify: 'inter-word'
  },
  backButton: {
    color: palette.primary.main
  },
  link: {
    wordWrap: 'break-word'
  },
  bulletlist: {
    fontSize: '0.9rem'
  }
}))

const Row = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})(Box)

// const msg =
//   'We’re sorry there was a missing item from your order. We can try to offer you a refund or we can ask the outlet if they can send the correct items out again. Be aware that delivery is only possible with main meals and may take between 30 and 60 minutes to arrive'
// const msgArr = msg.split('\n')

export default function FAQPageDetail(props) {
  const { history, location } = props
  const { state } = location
  const message = state.answer
  const msgArr = message.split('\n')
  const styles = useStyles()
  const [isTngMp, setIsTngMp] = useState(true)

  useEffect(() => {
    // setFaq(menuList)
    // containURL(message, setUrl)
    handleCheckPlatform()
  }, [])
  const handleCheckPlatform = () => {
    let platform = props.location.state.platform
    if (platform == 'tngmp') {
      setIsTngMp(true)
    } else {
      platform = 'ecommerce'
      setIsTngMp(false)
    }
  }

  return (
    <Box p={2}>
      {!isTngMp ? <BackButton {...props} /> : null}
      <Row my={1.5}>
        <Paper display="block" space={4} style={{ width: '75%' }}>
          <Typography variant="body1" className={styles.topic}>
            {state.topic}
          </Typography>

          <div
            dangerouslySetInnerHTML={{
              __html: purify.sanitize(message.replaceAll('\n', '<br>'), {
                sanitize: true
              })
            }}
            style={{
              marginTop: '12px',
              lineHeight: '20px',
              whiteSpace: 'pre-wrap' /* CSS3 */,
              whiteSpace: '-moz-pre-wrap' /* Mozilla, since 1999 */,
              whiteSpace: '-pre-wrap' /* Opera 4-6 */,
              whiteSpace: '-o-pre-wrap' /* Opera 7 */,
              wordWrap: 'break-word'
            }}
          />
        </Paper>
      </Row>
      <Row>
        {state.topic === 'How do I delete my FamilyMart membership?' &&
          !_.isEmpty(getToken()) && (
            <Button
              title={'Submit Form'}
              handleClick={() =>
                history.push({
                  pathname: `/request-deactivate`
                })
              }
            />
          )}
        {state.topic ===
          'Can I edit all personal / account information via the Mobile App?' &&
          !_.isEmpty(getToken()) && (
            <Button
              title={'Submit Form'}
              handleClick={() =>
                history.push({
                  pathname: `/request-edit`
                })
              }
            />
          )}
      </Row>
    </Box>
  )
}
